.sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 0;
    font-size: 3.5rem;
    background-color: #393E46;
    z-index: 999;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    &__social-text {
        padding: 0.6rem;
    }

    &__social-link {
        color: white;
        display: flex;
        text-decoration: none;
        &:hover {
            color: #00ADB5;
        }
        &--instagram {
            transition: all .3s;

            &::after {
                content: "@au.david_develops";
                // visibility: hidden;
                color: white;
                text-decoration: none;
                font-size: 2.5rem;
                transition: all .3s;
                margin-left: 0.6rem;
                // opacity: 0;
                // width: 0;
            }
            &:hover &::after {
                visibility: visible;
                opacity: 1;
                width: initial;
            }
        }
    }
    // position: fixed;
    // height: 100vh;
    // width: 6rem;
    // &__social-media {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     z-index: 999;
    //     margin: 0;
    //     padding: 0;
    //     background-color: white;
    // }
}