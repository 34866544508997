@import "../abstracts/mixins";
@import 'animate.css';

.body {
  // position: relative;
}

.navbar {
  background-color: #393e46;
  border-bottom: 0.5rem solid #00adb5;
  justify-content: space-between;
  z-index: 6001;
  // position: relative;
  // z-index: 10;
  //   flex-grow: 1;
  align-items: center;
  width: 100%;
  top: 0;
  transition: all 0.3s;
  // height: 6rem;
  // margin-top: 4.3rem;
  display: flex;
  // flex-grow: 1;
  &__route-items {
    list-style: none;
    display: flex;
    @include respond(tab-land) {
      opacity: 0;
      visibility: 0;
      width: 0;
      height: 0;
    }
  }

  &__screen {
    height: 0;
    width: 0;
    opacity: 0;
    transition: all .3s;
    background-color: #393e46;
    z-index: 5000;
    position: fixed;
    top: 0;
    left: 0;
    &-items {
      list-style: none;
      font-size: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-top: 5rem;
      padding: 3rem;
      height: 100%;
      // margin: auto 0;
      // align-items: center;
    }

    &-item {
      width: 50%;
      // &:hover {
      //   // animation: bounceInDown 1s ease-in-out;
      // }
    }

    &-link {
      text-decoration: none;
      color: white;
      // transition: all .3s;

      &-social {
        margin-right: 5rem;
      }
    }
    &-socials {
      display: flex;
      list-style: none;

    }
  }

  &__burger {
    &-btn {
      width: 5rem;
      height: 6px;
      background-color: white;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 5rem;
        height: 6px;
        background-color: white;
        border-radius: 5px;
      }

      &::before {
        transform: translateY(-1.6rem);
      }

      &::after {
        transform: translateY(1.6rem);
      }
    }

    &-group {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
      display: none;
      margin-left: 4rem;
      z-index: 6000;

      @include respond(tab-land) {
        visibility: visible;
        display: initial;
        opacity: 1;
        width: initial;
        height: initial;
        // margin-left: 10rem;
      }
    }

    &-group.open &-btn {
      transform: translateX(-5rem);
      background: transparent;
    }

    &-group.open &-btn::before {
      transform: rotate(45deg) translate(3.5rem, -3.5rem);
    }

    &-group.open &-btn::after {
      transform: rotate(-45deg) translate(3.5rem, 3.5rem);
    }
  }

  &__route-item {
    margin: 3rem;
    justify-content: flex-start;
  }

  &__route-link {
    text-decoration: none;
    color: white;
    font-size: 2.25rem;
    transition: all 0.1s;

    &:hover,
    &:target {
      border-bottom: 3px solid #00adb5;
    }
  }

  &__logo {
    // flex-grow: 3;
    justify-content: center;
    z-index: 6000;
    @include respond(tab-port) {
      margin: 0 auto;
    }
    &-img {
      height: 10rem;
      margin-top: 3px;
      width: 9.5rem;
      justify-content: center;
    }
  }

  &__social-icons {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include respond(tab-port) {
      display: none;
    }
  }

  &__social-icon {
    margin: 30px;
    @include respond(tab-land) {
      &:last-child {
        opacity: 0;
        visibility: hidden;
        //   display: none;
        width: 0;
        height: 0;
      }
    }

    &-link {

      &--superprof {
        width: 3rem;
        height: 3rem;
      }

      &--subscribe {
        background-color: white;
        color: #393e46;
        padding: 1rem;
        text-decoration: none;
        font-size: 2.25rem;

        &:hover,
        &:target {
          background-color: darken(white, 10%);
        }
      }

      &--instagram,
      &--fiverr,
      &--twitter {
        text-decoration: none;
        font-size: 2.6rem;
        color: white;
        &:hover,
        &:target {
          color: #00adb5;
        }
      }
    }
  }
}
