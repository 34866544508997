.footer {
    background-color: #393E46;
    padding: 3rem;

    &__find-us {
        &__social-text {
            &--email {
                margin-bottom: 1rem;
            }
        }
    }

    &__icons {
        position: relative;
        bottom: -3px;
    }

    &__main {
        display: grid;
        grid-template-columns: repeat(auto-fit, 40rem);
        justify-content: center;
        width: 100%;
        justify-items: center;
        // justify-content: space-around;
        // &-section {
        //     flex-basis: 33.33333%;
        // }
        &-section {
            &--logo {
                @include respond(tab-port) {
                    grid-column: span 2;
                }
            }
        }
    }

    &__header {
        color: #00ADB5;
        font-size: 3.5rem;
    }

    &__newsletter-signup {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;
        text-align: left;

        &-entry {
            padding: 1rem;
            border-radius: 5px;
            margin-right: 1rem;
            border-color: #00ADB5;
            font-size: 1.75rem;
            background-color: transparent;
            color: white;
            &:focus {
                outline-color: #00ADB5;
            }

            &::placeholder {
                color: white;
            }
        }

        &-entry:focus ~ &-btn {
            background-color: darken(#00ADB5, 10%);
        } 

        &-btn {
            padding: 1rem;
            font-size: 1.75rem;
            border-radius: 5px;
            margin-right: 1rem;
            border-color: #00ADB5;
            background-color: transparent;
            cursor: pointer;
            outline: #00ADB5;
            transition: all .3s;
            color: white;

            &:hover {
                background-color: darken(#00ADB5, 10%);
            }
        }
    }

    &__sub-header {
        font-size: 2rem;
        color: #fff;
        font-weight: 400;
        justify-content: flex-start;
        margin-bottom: 1rem;
    }

    &__copyright-msg {
        color: white;
    }

    ul {
        list-style: none;
        li {
            color: white;
            font-size: 2rem;
            cursor: pointer;
            &:hover a{
                border-bottom: 3px solid #00797e;
                font-size: 2.5rem;
            }
            a {
                text-decoration: none;
                transition: all .2s;
                color: white;
            }
        }
    }
}