.skills {
    display: grid;
    grid-template-columns: repeat(auto-fit, 45rem);
    justify-content: center;
    gap: 2rem;

    // &:last-child {
        
    // }

    &__ratings {
        display: flex;
        flex-direction: column;
        // flex-basis: 100%;
        &--3 {
            // grid-column: span 2;
            // @include respondPerfect(870, 1530) {
                
            // }
        }


    }

    &__separator {
        font-size: 10rem;
        align-self: center;
    }

    &__description {
        display: flex;
        font-size: 3rem;
        align-self: flex-end;
    }

    &__header {
        align-self: flex-end;

    }

    &__heading {
        margin: 4rem 7rem;
    }

    &__icon {
        padding: 3px 5px 0 0;
    }
    
    margin: 4rem 7rem;
}

.MuiRating-iconFilled, .MuiRating-iconHover {
    color: $color-primary;
}

.MuiRating-icon {
    font-size: 4rem;
}