

.portfolio {
    margin: 4rem 7rem;
    
    &__projects {
        display: grid;
        grid-template-columns: repeat(auto-fit, 45rem);
        justify-content: center;
    }

    &__card {
        display: flex;
        flex-direction: column;
        padding: 3rem 0;
        // flex: 1 1 33.33333%;
        align-items: center;
        border: 1px solid #00ADB5;
        text-align: center;
        // transition: all .3s ease-out .3s;
        background-size: cover;
        transition: all .3s ease-out .3s;

        &--1 {
            background-image: url("https://i.ibb.co/fxghdBF/github-finder.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/fxghdBF/github-finder.png");
            }
        }

        &--2 {
            background-image: url("https://i.ibb.co/j5wHZfc/todolist-home.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/j5wHZfc/todolist-home.png");
            }
        }

        &--3 {
            background-image: url("https://i.ibb.co/rf83NRV/tindog1.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/rf83NRV/tindog1.png");
            }
        }

        &--4 {
            background-image: url("https://i.ibb.co/Xznbd5p/signup1.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/Xznbd5p/signup1.png");
            }
        }

        &--5 {
            background-image: url("https://i.ibb.co/mcYz2tX/blog1-1.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/mcYz2tX/blog1-1.png");
            }
        }

        &--6 {
            background-image: url("https://i.ibb.co/94nT9zR/natours1.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/94nT9zR/natours1.png");
            }
        }

        &--7 {
            background-image: url("https://i.ibb.co/dWkRrz8/Screen-Shot-2021-10-19-at-7-48-45-pm.png");

            &:hover {
                background-image: linear-gradient(rgba(white, .65), rgba(white, .65)), url("https://i.ibb.co/dWkRrz8/Screen-Shot-2021-10-19-at-7-48-45-pm.png");
            }
        }

        
    }

    &__card:hover &__header {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    &__card:hover &__description {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    &__header {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-8rem);
        transition: all .3s ease-out .3s;
        &::after {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 10%; /* Change this to whatever width you want. */
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-bottom: 2px solid #00ADB5;
            margin: 0 auto;
        }

        &--modal {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: none;
        }
    }

    &__description {
        visibility: hidden;
        opacity: 0;
        transform: translateY(8rem);
        transition: all .3s ease-out .3s;
    }
}