.subscribe {
    .modal {
        &__card {
            display: block;
            .footer__newsletter-signup {
                &-entry {
                    color: black;
                    &::placeholder {
                        color: black;
                    }
                }
                &-btn {
                    color: black;
                }
            }
        }
    }
}