.btn {
    text-decoration: none;
    
    font-size: 2rem;
    text-transform: uppercase;
    padding: 1rem;
    animation: buttonEntry .5s ease;
    border-radius: 5px;

    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 1rem 2rem rgba(black, .2);
    }
    
    &:active {
        transform: translateY(-1px);
        box-shadow: 0 0.5rem 1rem rgba(black, .2);
    }
    

    &--change {
        // position: absolute;
        // top: 65%;
        // display: block;
        // left: 50%;
        z-index: 1;
        animation-name: buttonFloating;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-fill-mode: backwards;
        transition: all .3s;
        margin: 4rem 0;
        align-self: center;
        color: #eee;
        

        &:hover {
            animation-play-state: paused;
            background-color: #393E46;
        }

        &__learn-more--change {
            display: none;
        }

        &:hover &__learn-more--change {
            display: initial;
        }

        &:hover &__learn-more {
            display: none;
        }

        span {
            border: none;
        }
    }

    &--learn-more {
            color: black;
            border: 2px solid #00ADB5;
            transition: all .3s;
            cursor: pointer;

        &:hover {
            background-color: #00ADB5;
            color: white;
        }
    }
}