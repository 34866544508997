.donate {

    background-image: linear-gradient(rgba(black, .5), rgba(black, .5)), url("https://image.cnbcfm.com/api/v1/image/106806533-1607102792308-gettyimages-1283072124-20200930_fooddrive2_sdil0383.jpeg?v=1607102809");
    height: 70rem;
    background-attachment: fixed;

    &__cta {
        position: absolute;
        height: 75%;
        width: 100%;
        overflow: hidden;
        opacity: .7;
        
        
        

        &__img {
            width: 100%;
            height: 100%;
            background-size: cover;
            
        }
    }
    &__heading {
        font-size: 8rem;
        z-index: 2;
        &::after {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 30%; /* Change this to whatever width you want. */
            margin: 0 auto;
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-bottom: 2px solid #00ADB5;
        }
    }

    &__paragraph {
        z-index: 2;
        font-size: 3.5rem;
        margin-top: 2rem;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: white;
        justify-content: center;
        margin: auto 0;
    }

    &__options {
        display: flex;
        z-index: 2;
    }

    &__button {
        margin-right: 5rem;
    }

    &__button {
        color: white;
    }
}