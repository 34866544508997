@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media(max-width: 37.5em) {@content} 
    }
    @if $breakpoint == tab-port {
        @media(max-width: 56.25em) {@content} 
    }
    @if $breakpoint == tab-land {
        @media(max-width: 75em) {@content} 
    }
    @if $breakpoint == big-desktop {
        @media(min-width: 112.5em) {@content} 
    }
}

@mixin respondPerfect($min, $max) {
    @media screen and (min-width: $min) and (max-width: $max) {@content}
}

// 592px
// 900px
// 1200px
// 1800px