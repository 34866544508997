.modal {
    background-color: rgba(black, .8);
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    position: fixed;
    // z-index: 6050;
    opacity: 0;
    visibility: hidden;

    h1 {
        @media (max-width: 1400px) {
            font-size: 7.25rem;
        }
    }

    &:target {
        opacity: 1;
        visibility: visible;
        height: 100vh;
        width: 100%;
        z-index: 10000;
    }

    &:target &__card {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    &__close {
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 4rem;
        text-decoration: none;
        color: #393E46;
    }

    &__card {
        background-color: white;
        position: relative;
        width: 65%;
        height: 65%;
        position: absolute;
        @include respond(phone) {
            width: 80%;
            height: 80%;
        }

        @media (min-width: 1400px) {
            height: 75%;
        }
        top: 50%;
        left: 50%;
        // top: ;
        display: grid;
        grid-template-areas: 
            "images images"
            "header header"
            "body-left body-right"
            "links links"
        ;
        grid-template-columns: repeat(2, 1fr);
        padding: 2rem;
        border-radius: 3px;
        opacity: 0;
        transform: translate(-40%, -40%) scale(.25);
        transition: all .5s .2s;
    }

    &__references {
        list-style: none;
        display: flex;
        justify-content: center;
        grid-area: links;
        // align-items: flex-end;

        &__reference {
            // align-items: flex-end;
            &-icon {
                position: relative;
                top: 2px;
            }

            &-link {
                text-decoration: none;

            }
        }
    }

    &__header {
        text-align: center;
        grid-area: header;
    }

    &__description {
        // flex-basis: 50%;
        // height: 100%;
        // margin-right: 1rem;
        padding: 1rem;
        grid-area: body-left;
    }

    &__outline {
        // flex-basis: 50%;
        // height: 100%;
        padding: 1rem;
        grid-area: body-right;
        justify-self: end;
    }

    &__images {
        background-size: cover;
        grid-area: images;


        &--image1 {
            // grid-area: image1;
        }

        &--image2 {
            // grid-area: image2;
        }

        &--image1, &--image2 {
            width: 50%;
        }
    }

    &__subscribe {

        &__header {
            // position: absolute;
            top: 7rem;
            left: 3rem;
            text-align: center;
            color: white;

            &-section {
                width: 100%;
                height: 60%;
                background-image: linear-gradient(to right bottom, darken(rgba(#00adb5, .7), 10%), darken(rgba(#00adb5, .7), 30%)) ,url("https://images.unsplash.com/photo-1603791445824-0050bd436b6d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80");
            }
        }
    }

}