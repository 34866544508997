.scroll-top {
    position: fixed;
    bottom: 2.5rem;
    left: 2.5rem;
    background-color: #00ADB5;
    border-radius: 100rem;
    box-shadow: 0 0.1rem 0.5rem black;
    animation: bounce 5s infinite;
    animation-delay: 2s;
    transition: all .3s;
    z-index: 9000;
    transition: all .3s;

    &__btn {
        text-decoration: none;

        &::before {
            content: "\2193";
            font-size: 4rem;
            color: white;
            text-decoration: none;
            padding: 1rem;
            position: relative;
            opacity: var(--downArrOpacity, 1);
            visibility: var(--downArrVisibility, "visible");
        }

        &::after {
            content: "\2191";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: var(--upArrOpacity, 0);
            visibility: var(--upArrVisibility, "hidden");
            font-size: 4rem;
            color: white;
            text-decoration: none;
        }
    }
}

#scroll-top {
    &:hover {
        opacity: 1;
    }
}