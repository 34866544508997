.cta {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 4rem 0;
    text-align: center;

    &__slogan {
        font-size: 5rem;
        color: #00ADB5;
        transition: all .3s;

        &:hover {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
        }
    }

    &__slogan:hover + &__chat-container &__chat {
        -webkit-text-fill-color: initial;
    }

    &__chat {
        font-size: 12rem;
        color: #00ADB5;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        cursor: pointer;
        font-weight: 800;
        transition: all .3s;
        padding: 1rem;

        &:hover {
            border: 3px solid #00ADB5;
            border-radius: 1rem;
            box-shadow: 0.5rem 1rem 0.5rem black;
            transform: translateY(-3px);
        }

        &:active {
            box-shadow: 0.25rem 0.5rem 0.5rem black;
            transform: translateY(-1px);
        }
    }
}