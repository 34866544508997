.homevid {
    height: 100vh;
    width: 100%;
    background-color: #151515;
    position: relative;
    z-index: -1;
    // display: block;

    // @include respond(phone) {
    //     display: none;
    // }

    &__content {
        width: 100%;
        position: absolute;
        top: 50%;
        z-index: -5;
        left: 0;
        transform: translate(0,  -50%);
        background-size: cover;
    }
}

.heading {
    z-index: 400;
    // position: absolute;
    &__intro {
        width: 100%;
        align-items: center;
    }
}