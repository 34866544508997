.about {

    &__cta {
        &::before {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 30%; /* Change this to whatever width you want. */
            margin: 0 auto;
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-top: 4px solid #00ADB5;
        }

        &::after {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 30%; /* Change this to whatever width you want. */
            margin: 0 auto;
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-bottom: 4px solid #00ADB5;
        }
    }

    &__not-values {
        margin: 4rem 7rem;
        display: flex;
        flex-wrap: wrap;
        @include respond(tab-land) {
            justify-content: center;
        }
    }

    &__shape {
        shape-outside: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        width: 32rem;
        height: 32rem;
        margin-right: 4rem;
        background-color: $color-primary;
        position: relative;
        flex-basis: 30%;
    }

    .social {
        &-list {
            justify-content: space-evenly;
            width: 100%;
            margin-bottom: 2rem;
        }
    }

    &__description {
        flex-basis: 70%;
        margin-bottom: 4rem;
    }

    &__david-img {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 30rem;
        height: 30rem;
        clip-path: circle(50% at 50% 50%);
        // transform: translate(-38rem, -24rem);
    }

    &__values {
        display: grid;
        grid-template-columns: repeat(auto-fit, 35rem);
        width: 100%;
        gap: 2rem;
        justify-content: center;
        background-image: url("https://images.unsplash.com/photo-1490604001847-b712b0c2f967?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1853&q=80");
        height: 80vh;
        align-content: center;
        align-items: stretch;
        background-size: cover;
        @include respond(phone) {
            height: 120vh;
        }
        // justify-items: center;

        &-content {
            // margin-right: 2rem;
            background-color: rgba(white, .65);
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 2rem;
            flex-basis: 25%;
            transition: all .2s;
            // align-items: flex-start;

            &:hover {
                box-shadow: -0.5rem 0.5rem 0.5rem black;
                transform: translateY(-10px);
            }
        }

        &-icon {
            font-size: 10rem;
            background-image: linear-gradient(to right, #00adb5, darken(#00adb5, 10%));
            -webkit-text-fill-color: transparent;
            background-clip: text;
            // -webkit-background-clip: text;
        }

        &-message {
            font-size: 2.5rem;
            font-weight: 400;
        }
    }
}