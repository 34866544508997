.contact {
  .social {

    &-list {
      flex-direction: column;
    }

    &-acc {
      font-size: 2.5rem;
    }

    &-item {
      &:hover {
        width: 38rem;
    }
    }
  }

  &__social {
    &-container {
      flex-basis: 30%;
      background-color: white;
      box-shadow: -0.25rem 2rem 2rem black;
      transform: translateY(-8rem);
      border-radius: 5px;
      padding: 2rem;
      // justify-content: center;
      // align-items: center;
      @include respond(tab-port) {
        margin-left: 2rem;
        margin-top: 2rem;
    }
      // height: auto;
      @include respond(phone) {
        flex-direction: row;
        margin-left: 2rem;
        margin-top: 2rem;
        flex-basis: 100%;
        justify-content: space-evenly;
    }
    }
  }

  &__heading-group {
    height: 85vh;
    background-image: linear-gradient(rgba(black, 0.5), rgba(black, 0.5)),
      url("https://www.lifewire.com/thmb/oUxCLC4iL5LX-SrBbjb504mbMXY=/2119x1414/filters:no_upscale():max_bytes(150000):strip_icc()/making-connections-with-people-all-around-the-world-939722082-adb26a94d3a6429092cd0fc418372820.jpg");
    background-size: cover;
    width: 100%;
    color: white;
    position: relative;
  }

  &__heading {
    position: absolute;
    top: 50%;
    left: 50%;
  //   @include respond(tab-port) {
  //     font-size: 4rem;
  // }
    transform: translate(-50%, -50%);
    // width: 75%;
    text-align: center;
  }

  &__body {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
  }

  &__form {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    padding: 2rem;
    background-color: white;
    box-shadow: 0.25rem 2rem 2rem black;
    transform: translateY(-8rem);
    flex-basis: 60%;
    margin-left: 2rem;
    border-radius: 5px;
    margin-right: 4rem;

    @include respond(tab-port) {
      flex-basis: 100%;
  }
  }

  &__input {
    font-family: "Fira sans";
    font-size: 2rem;
    margin: 1rem 0;
    width: 40%;
    padding: 5px;
    border: 2px solid black;
    border-radius: 5px;

    &--msg {
      width: initial;
      resize: vertical;
    }
  }

  &__btn {
    background-color: white;
  }

  // &__social-item &__social-link:hover &__social-icon {
  //     &--twit {
  //         background-color: #1da1f2;
  //     }
  // }

  &__label {
    font-size: 3rem;
    background-image: linear-gradient(to right, #00adb5, darken(#00adb5, 10%));
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  nav {
    position: fixed;
    top: 0;
    // left: 0;
    width: 100%;
  }
}
