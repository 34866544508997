.social {
    &-list {
        list-style: none;
        display: flex;
        border-radius: 5px;
        margin-right: 2rem;
        // align-items: fle;
    }
    
    &-item {
        font-size: 4rem;
        margin-bottom: 2rem;
        border-radius: 5rem;
        height: 8rem;
        width: 8rem;
        background-color: white;
        box-shadow: 0 1rem 1rem rgba(black, .5);
        overflow: hidden;
        transition: all .2s ease-out;
    
        &:hover {
            width: 50rem;
        }
    }
    
    &-link {
        padding: 2rem;
        text-decoration: none;
        flex-basis: 100%;
        display: flex;
        color: #393E46;
        
        // color: white;
        // display: inline-block;
        // text-align: center;
    }
    
    &-icon {
        margin-right: 2rem;
        transition: all .2s ease-out;
        border-radius: 5rem;
    }
    
    &-acc {
        position: relative;
        bottom: 8px;
    }
}