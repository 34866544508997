.heading {
    margin-bottom: 3rem;
    text-transform: uppercase;
    &__intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-primary {
        font-size: 10rem;
        @include respond(phone) {
            font-size: 5rem;
        }

        &--contact {
            @include respond(tab-port) {
                font-size: 8rem;
            }
        }
        &--white {
            color: white;
        }
    }

    &-secondary {
        font-size: 4rem;
        font-weight: 700;
        letter-spacing: 1.1rem;
        &::after {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 30%; /* Change this to whatever width you want. */
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-bottom: 2px solid #00ADB5;
        }
        
    }

    &-subheading {
        font-size: 2.5rem;
        font-weight: 500;
        &::after {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */ /* This will center the border. */
            width: 30%; /* Change this to whatever width you want. */
            padding-top: 20px; /* This creates some space between the element and the border. */
            border-bottom: 2px solid #00ADB5;
        }
    }

    &-tertiary {
        font-size: 3rem;
    }
}

.paragraph {
    font-size: 2rem;
    font-weight: 300;
    &:not(:last-child) {
        padding-bottom: 1.5rem;
    }

    &-bold {
        font-weight: 400;
    }
}