*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @include respond(tab-land) {
        font-size: 56.25%;
    }
    @include respond(tab-port) {
        font-size: 50%;
    }
    @include respond(big-desktop) {
        font-size: 75%;
    }
}

body {
    box-sizing: border-box;
    font-family: "Fira sans";
    color: #393E46;
}