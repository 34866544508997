@keyframes buttonFloating {
    0% {
        transform: translateY(-1rem);
        box-shadow: 0 1.5rem 1.5rem black;
    }

    50% {
        transform: translateY(-1px);
        box-shadow: 0 0.5rem 0.5rem black;
    }

    100% {
        transform: translateY(-10px);
        box-shadow: 0 1.5rem 1.5rem black;
    }
}

@keyframes buttonEntry {
    0% {
        opacity: 0;
        transform: translate(0, -50%), translateY(5px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0), translateY(0);
    }
}

@keyframes lightSpeedInLeft {
    from {
      transform: translate3d(-100%, 0, 0) skewX(30deg);
      opacity: 0;
    }
  
    60% {
      transform: skewX(-20deg);
      opacity: 1;
    }
  
    80% {
      transform: skewX(5deg);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .lightSpeedInLeft {
    animation-name: lightSpeedInLeft;
    animation-timing-function: ease-out;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  
    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }
  
    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }
  
    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }
  
    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
  
  .bounceIn {
    animation-duration: calc(var(--animate-duration) * 0.75);
    animation-name: bounceIn;
}

@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  
    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0) scaleY(3);
    }
  
    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0) scaleY(0.9);
    }
  
    75% {
      transform: translate3d(0, -10px, 0) scaleY(0.95);
    }
  
    90% {
      transform: translate3d(0, 5px, 0) scaleY(0.985);
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .bounceInDown {
    animation-name: bounceInDown;
  }

  $arrowRotation: 180deg;

  @keyframes bounce {
    from,
    20%,
    53%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0) scaleY(1.1);
    }
  
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0) scaleY(1.05);
    }
  
    80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0) scaleY(0.95);
    }
  
    90% {
      transform: translate3d(0, -4px, 0) scaleY(1.02);
    }
  }
  
  .bounce {
    animation-name: bounce;
    transform-origin: center bottom;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  